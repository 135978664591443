import _toIndexedObject from "../internals/to-indexed-object";
import _addToUnscopables from "../internals/add-to-unscopables";
import _iterators from "../internals/iterators";
import _internalState from "../internals/internal-state";
import _objectDefineProperty from "../internals/object-define-property";
import _iteratorDefine from "../internals/iterator-define";
import _createIterResultObject from "../internals/create-iter-result-object";
import _isPure from "../internals/is-pure";
import _descriptors from "../internals/descriptors";
var exports = {};
var toIndexedObject = _toIndexedObject;
var addToUnscopables = _addToUnscopables;
var Iterators = _iterators;
var InternalStateModule = _internalState;
var defineProperty = _objectDefineProperty.f;
var defineIterator = _iteratorDefine;
var createIterResultObject = _createIterResultObject;
var IS_PURE = _isPure;
var DESCRIPTORS = _descriptors;
var ARRAY_ITERATOR = "Array Iterator";
var setInternalState = InternalStateModule.set;
var getInternalState = InternalStateModule.getterFor(ARRAY_ITERATOR);

// `Array.prototype.entries` method
// https://tc39.es/ecma262/#sec-array.prototype.entries
// `Array.prototype.keys` method
// https://tc39.es/ecma262/#sec-array.prototype.keys
// `Array.prototype.values` method
// https://tc39.es/ecma262/#sec-array.prototype.values
// `Array.prototype[@@iterator]` method
// https://tc39.es/ecma262/#sec-array.prototype-@@iterator
// `CreateArrayIterator` internal method
// https://tc39.es/ecma262/#sec-createarrayiterator
exports = defineIterator(Array, "Array", function (iterated, kind) {
  setInternalState(this, {
    type: ARRAY_ITERATOR,
    target: toIndexedObject(iterated),
    // target
    index: 0,
    // next index
    kind: kind // kind
  });
  // `%ArrayIteratorPrototype%.next` method
  // https://tc39.es/ecma262/#sec-%arrayiteratorprototype%.next
}, function () {
  var state = getInternalState(this);
  var target = state.target;
  var index = state.index++;
  if (!target || index >= target.length) {
    state.target = undefined;
    return createIterResultObject(undefined, true);
  }
  switch (state.kind) {
    case "keys":
      return createIterResultObject(index, false);
    case "values":
      return createIterResultObject(target[index], false);
  }
  return createIterResultObject([index, target[index]], false);
}, "values");

// argumentsList[@@iterator] is %ArrayProto_values%
// https://tc39.es/ecma262/#sec-createunmappedargumentsobject
// https://tc39.es/ecma262/#sec-createmappedargumentsobject
var values = Iterators.Arguments = Iterators.Array;

// https://tc39.es/ecma262/#sec-array.prototype-@@unscopables
addToUnscopables("keys");
addToUnscopables("values");
addToUnscopables("entries");

// V8 ~ Chrome 45- bug
if (!IS_PURE && DESCRIPTORS && values.name !== "values") try {
  defineProperty(values, "name", {
    value: "values"
  });
} catch (error) {/* empty */}
export default exports;